
import ValidationMixin from "@/mixins/validation";
import {
  ResetPasswordPayload,
  SignInTypes,
} from "@/store/modules/auth/auth.types";
import { DefaultState } from "@/types/types";
import { Validator } from "@/types/validator";
import { Component, Mixins, Ref } from "vue-property-decorator";
import { namespace } from "vuex-class";

const Auth = namespace("Auth");

@Component({
  components: {
    ForgotPassword: () => import("@/components/auth/ForgotPassword.vue"),
  },
})
export default class Login extends Mixins(ValidationMixin) {
  public isValid = false;

  @Auth.State(SignInTypes.RESET_PASSWORD_STATE)
  public resetPasswordState!: DefaultState;

  public passwordChange: PasswordChange = {
    NewPassword: "",
    ConfirmPassword: "",
  };

  @Ref("form") public formRef!: Validator;

  @Auth.Action(SignInTypes.RESET_PASSWORD)
  private resetPassword!: (payload: ResetPasswordPayload) => Promise<void>;

  get confirmPasswordRules(): unknown[] {
    return [
      (v: string): boolean | string => !!v || "Password confirmation required",
      (v: string): boolean | string =>
        v === this.passwordChange.NewPassword || "Passwords donot match.",
    ];
  }

  async handlePasswordReset(): Promise<void> {
    await this.formRef.validate();

    if (this.isValid)
      await this.resetPassword({
        resetToken: this.$route.query.token as string,
        newPassword: this.passwordChange.NewPassword,
      });
  }
}

type PasswordChange = {
  NewPassword: string;
  ConfirmPassword: string;
};
